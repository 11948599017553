import React from 'react';
import homepage from './img/Homepage.png';
import './App.css';

function App() {
    return (
        <div className='App'>
            <img src={homepage} className='homepage-img' alt='homepage' />
        </div>
    );
}

export default App;
